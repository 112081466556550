import { initializeFaro, getWebInstrumentations } from "@grafana/faro-web-sdk";
import {userDataStore} from './UserData.Store'

class FaroService {
  constructor() {
    this.faroInstance = null;
    this.currentSessionId = "";
  }

  /**
   * Initializes the Faro instance with the given session ID.
   * @param {string} sessionId - The session ID for tracking
   */
  initialize(sessionId) {
    this.currentSessionId = sessionId;
    sessionStorage.setItem("sessionId", sessionId);

    // Clear any existing Faro session
    sessionStorage.removeItem("com.grafana.faro.session");

    this.faroInstance = initializeFaro({
      url: `${window.location.origin}/faro`, // OTLP endpoint for Alloy
      apiKey: userDataStore.getters.envValues.faroApiKey,
      app: {
        name: "VKYC",
        version: "1.0.0",
        environment: userDataStore.getters.envValues.faroEnvironment,
      },
      sessionTracking: {
        enabled: true,
        generateSessionId: () => sessionStorage.getItem("sessionId") || "",
      },
      instrumentations: [
        ...getWebInstrumentations({
          captureConsole: true,
          captureErrors: true,
          captureNetwork: true,
          capturePerformance: true,
        })
      ],
    });

    console.log("[FARO] Initialized with session ID:", sessionId);
  }

  /**
   * Ends the current session.
   * This clears the stored session so that new telemetry will start a new session.
   */
  endSession() {
    this.currentSessionId = "";
    sessionStorage.removeItem("sessionId");
    sessionStorage.removeItem("com.grafana.faro.session");

    console.log("[FARO] Ended current session.");

    if (
      this.faroInstance &&
      this.faroInstance.api &&
      typeof this.faroInstance.api.resetSession === "function"
    ) {
      this.faroInstance.api.resetSession();
    }
  }

  /**
   * Returns the Faro instance.
   */
  getInstance() {
    return this.faroInstance;
  }
}

export default new FaroService();
